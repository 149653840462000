import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { Container, Logo, ThemeContext } from 'components/common'
// import Copyrights from './Copyrights'
import Social from './Social'
import {
  Wrapper,
  FooterContainer,
  FullContainer,
  List,
  Brand,
  StyledLogo,
} from './styles'

export const Footer = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <FooterContainer theme={theme}>
      <FullContainer>
        <Wrapper as={Container}>
          <List>
            <li>Follow me on</li>
            <Social />
          </List>
        </Wrapper>
      </FullContainer>
      {/*<Copyrights />*/}
    </FooterContainer>
  )
}
