module.exports = {
	defaultTitle: 'Gaurav Abbhi',
	logo: 'https://gauravabbhi.com/favicon/logo-48.png',
	author: 'Gaurav Abbhi (Karan)',
	url: 'https://gauravabbhi.com',
	legalName: 'Gaurav Abbhi',
	defaultDescription:
		"I'm Gaurav Abbhi aka Karan, a self-taught full stack developer, interested in the web, mobile application development & tech in general.",
	socialLinks: {
		twitter: 'https://twitter.com/gauravabbhi',
		github: 'https://github.com/gauravabbhi',
		linkedin: 'https://www.linkedin.com/in/gaurav-abbhi/',
		instagram: 'https://www.instagram.com/gauravabbhi',
	},
	googleAnalyticsID: 'UA-88875900-1',
	themeColor: '#00c6ff',
	backgroundColor: '#00c6ff',
	siteRss: '/rss.xml',
	social: {
		facebook: '165244147328608',
		twitter: '@gaurav-abbhi'
	},
	address: {
		city: 'Banglore',
		region: 'Brookfield',
		country: 'India',
		zipCode: '560037'
	},
	contact: {
		email: 'gaurav.abbhi@me.com',
		phone: '+91-9915792839'
	},
	foundingDate: '2016'
}
