import styled from 'styled-components'

export const FooterContainer = styled.footer`
  ${({ theme }) =>
    theme === 'dark' &&
    `
			background: #212121;
	`};
`

export const FullContainer = styled.div`
  width: 100%;
  padding: 1rem 0 0 0;
  color: #fff;
  background-image: linear-gradient(10deg, #0072ff 0%, #00c6ff 100%);
`

export const List = styled.ul`
  list-style: none;
  align-self: center;
  margin-bottom: 0;

  a {
    color: #fff;
  }
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 680px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`
